export enum DrawerPosition {
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TransitionName {
  'SLIDE_LEFT' = 'slide-left',
  'SLIDE_RIGHT' = 'slide-right',
  'SLIDE_UP' = 'slide-up',
  'SLIDE_DOWN' = 'slide-down',
  'FADE' = 'fade',
  'NONE' = 'none',
  'DEFAULT' = 'slide-up',
}

export enum DrawerSize {
  AUTO = 'AUTO',
  FULL = 'FULL',
}

export interface DrawerItem {
  id?: string;
  ref?: any;
  component: any;
  data: any;
  events: any;
  canCloseForModal?: boolean;
  useCloseButton?: boolean;
  useModal?: boolean;
  position?: DrawerPosition;
  animationName?: string;
  animationDuration?: number;
  bgColor?: string;
  drawerEvent?: string;
  size?: DrawerSize;
}

export interface DrawerState {
  items: DrawerItem[];
  observers: any;
  transitionName?: TransitionName;
}
