import loadScript from 'load-script';

import { AnalyticsModule } from '..';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default class GoogleAnalytics implements AnalyticsModule {
  private _trackingId: string;
  private _mappingEvents: any;
  public isLoaded: boolean = false;

  _loadScript(callback = (error, script) => {}) {
    window.dataLayer = window.dataLayer || [];

    if (this._trackingId) {
      const getScriptUrl = `https://www.googletagmanager.com/gtag/js?id=${this._trackingId}`;
      loadScript(getScriptUrl, (error, script) => {
        if (error) {
          callback(error, script);
          return;
        }
        callback(error, script);
      });

      this._gtag('js', new Date());
      this._gtag('config', this._trackingId);
      this.isLoaded = true;
    } else {
      console.error('undefined trackingId');
    }
  }

  _gtag(a?: any, b?: any, c?: any) {
    window.dataLayer.push(arguments);
  }

  constructor({ trackingId }) {
    this._trackingId = trackingId;

    this._mappingEvents = {};
  }

  init() {
    this._loadScript((error, script) => {});
  }

  event(name: any, options?: { common: any; google: any; [key: string]: any }) {
    const { common = {}, google = {} } = options ?? {};

    const eventName = this._mappingEvents[name] ?? name;

    if (!this.isLoaded) {
      console.error('Has not been init yet GoogleAnalytics');
      return;
    }

    if (!eventName) {
      // mappingEventName이 빈값인 경우 해당 이벤트를 보내지 않음
      return;
    }

    this._gtag('event', eventName, {
      ...common,
      ...google,
    });
  }

  setUserId(id: string) {}

  setUserProperty(key: string, value: any) {}

  addUserProperty(key: string, value: number) {}

  clearUser() {}
}
