import config from '@/config';
import Airbridge from './lib/airbridge';
import Amplitude from './lib/amplitude';
import GoogleAnalytics from './lib/google.analytics';

enum AnalyticsType {
  None = 'NONE',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  Amplitude = 'AMPLITUDE',
  Airbridge = 'AIRBRIDGE',
}

function createModule(type) {
  const analytics = config.get('analytics');

  if (type === AnalyticsType.GoogleAnalytics) {
    return new GoogleAnalytics(analytics.google);
  }

  if (type === AnalyticsType.Amplitude) {
    return new Amplitude(analytics.amplitude);
  }

  if (type === AnalyticsType.Airbridge) {
    return new Airbridge(analytics.airbridge);
  }

  return {
    init() {
      console.warn('선언되지 않은 타입입니다');
    },
    event() {
      console.warn('선언되지 않은 타입입니다');
    },
  };
}

export interface AnalyticsModule {
  init();
  event(
    name: string,
    options?: {
      common: any;
      google: any;
      airbridge: any;
      amplitude: any;
      [key: string]: any;
    },
  );
  setUserId(id: string);
  setUserProperty(key: string, value: string);
  addUserProperty(key: string, value: number);
  clearUser();
}

class AnalyticPlugin {
  _types: AnalyticsType[];
  _modules: any;

  _loadModules() {
    this._types.forEach((type) => {
      const module = createModule(type);

      this._modules[type] = module;
    });
  }

  constructor(options: { types: AnalyticsType[] }) {
    const { types } = options;

    this._types = types;
    this._modules = {};
    this._loadModules();
  }

  get airbridge() {
    return this._modules[AnalyticsType.Airbridge];
  }

  get google() {
    return this._modules[AnalyticsType.GoogleAnalytics];
  }

  get amplitude() {
    return this._modules[AnalyticsType.Amplitude];
  }

  init() {
    try {
      for (const type in this._modules) {
        const module = this._modules[type];
        module.init();
      }
    } catch (error) {
      console.error(error);
    }
  }

  setUserId(id: string) {
    for (const type in this._modules) {
      const module = this._modules[type];
      module.setUserId(id);
    }
  }

  setUserProperty(key: string, value: string) {
    for (const type in this._modules) {
      const module = this._modules[type];
      module.setUserProperty(key, value);
    }
  }

  addUserProperty(key: string, value: number) {
    for (const type in this._modules) {
      const module = this._modules[type];
      module.addUserProperty(key, value);
    }
  }

  setUserProperties(properties) {
    for (const property in properties) {
      this.setUserProperty(property, properties[property]);
    }
  }

  clearUser() {
    for (const type in this._modules) {
      const module = this._modules[type];
      module.clearUser();
    }
  }

  event(name: string, options?: any) {
    setTimeout(() => {
      try {
        for (const type in this._modules) {
          const module = this._modules[type];
          module.event(name, options);
        }
      } catch (error) {
        console.error(error);
      }
    }, 0);
  }
}

let __SHARED_ANALYTICS__;

export function useAnalytics() {
  return __SHARED_ANALYTICS__;
}

const plugin = {
  install: (app, options) => {
    const analytics = new AnalyticPlugin({
      types: [AnalyticsType.Airbridge, AnalyticsType.Amplitude, AnalyticsType.GoogleAnalytics],
    });
    analytics.init();

    __SHARED_ANALYTICS__ = analytics;
  },
};

export default plugin;
