<template>
  <div class="page-actions" :class="classNames" ref="refPageActions" v-show="!isKeyboardVisible">
    <div class="page-actions-content">
      <slot></slot>
    </div>
  </div>

  <resize-observer @notify="resizeActionHide" />
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useLayoutStore } from '@/stores/modules/layout';
import { useToastStore } from '@/stores/modules/toast';
import { createLogger } from '@/utils/logger';

const layout = useLayoutStore();
const toastModule = useToastStore();
const debug = createLogger('app:components:resources:layout:PageActions');
const props = defineProps({
  float: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: null,
  },
});
const classNames = computed(() => [props.float && 'floating', props.class ? props.class : '']);
const screen = ref(null);
const isKeyboardVisible = ref(false);
const refPageActions = ref(null);

const resizeActionHide = () => {
  layout.resize({});

  screen.value = {
    screenHeight: window.screen.height,
    availHeight: window.screen.availHeight,
    innerHeight: window.innerHeight,
    clientHeight: document.body.clientHeight,
    webviewHeight: window.visualViewport.height,
    bodyHeight: document.body.offsetHeight,
    testHeight: window.screen.availHeight * (document.body.clientHeight / window.screen.availHeight),
  };

  debug(
    'resize',
    screen.value.screenHeight * 0.7,
    screen.value.webviewHeight,
    screen.value.screenHeight * 0.7 < screen.value.testHeight,
  );

  if (screen.value.screenHeight * 0.7 < screen.value.testHeight) {
    isKeyboardVisible.value = false;
  } else {
    isKeyboardVisible.value = true;
  }
};
onMounted(() => {
  toastModule.setMargin({ bottom: refPageActions.value.clientHeight });
});
onBeforeUnmount(() => {
  toastModule.setMargin(null);
});
</script>

<style lang="scss" scoped>
.page-actions {
  @include shadow-level(shadow3);
  @include safe-area-inset-bottom;

  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: $page-actions-index;
  // background-image: linear-gradient(180deg, rgba($color-background-page, 0) 1.56%, $color-background-page 13.28%);
  background: $color-background-page;

  &.floating {
    position: static;
  }

  &-content {
    max-width: $max-content-width;
    margin: 0 auto;
    padding: unit(10) $page-side-margin unit(16);

    :deep() {
      button + button,
      button + a,
      a + button,
      a + a {
        margin-top: unit(12);
      }
    }
  }
}
</style>
