<template>
  <div :class="['page', route.name]" :style="state.styles">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <!-- <transition>
          <component :is="Component" />
        </transition> -->
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useWNInterface } from '@/plugins/vue-wni';
import { useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';

const route = useRoute();
const drawerModule = useDrawerStore();
const layoutStore = useLayoutStore();
const wni = useWNInterface();

const state = reactive({
  isDrawer: computed(() => !drawerModule.items.length),
  keyboard_height: computed(() => layoutStore.keyboardHeight),
  styles: computed(() => {
    if (!wni.isAndroid) {
      return;
    }

    if (state.keyboard_height > 0 && state.isDrawer) {
      return { '--keyboard-height': `${state.keyboard_height}px` };
    }
    return {};
  }),
});
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  box-sizing: content-box;

  &::after {
    content: '';
    display: block;
    height: var(--keyboard-height);
  }
}
</style>
