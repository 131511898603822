import _ from 'lodash';
import { defineStore } from 'pinia';

export enum ToastType {
  ALERT = 'ALERT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface ToastItem {
  id: string;
  type: ToastType;
  message: string;
  duration: number;
  useCloseButton: boolean;
}

export interface ToastMargin {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export interface ToastState {
  items: ToastItem[];
  margin: ToastMargin;
}

export const useToastStore = defineStore({
  id: 'toast',
  state: (): ToastState => ({
    items: [],
    margin: {
      top: null,
      right: null,
      bottom: null,
      left: null,
    },
  }),
  getters: {},
  actions: {
    clear() {
      this.items = [];
    },

    alert({ message }) {
      this.show({ type: ToastType.ALERT, message });
    },

    success({ message }) {
      this.show({ type: ToastType.SUCCESS, message });
    },

    error({ error }) {
      console.error(error);
      this.show({ type: ToastType.ERROR, message: error });
    },

    show(payload: { type: ToastType; message: any; duration?: number; clear?: boolean; useCloseButton?: boolean }) {
      const item: ToastItem = {
        id: _.uniqueId(),
        type: payload.type ?? ToastType.ALERT,
        message: payload.message ?? '',
        duration: payload.duration,
        useCloseButton: payload.useCloseButton ?? true,
      };

      if (payload.clear === true) {
        this.clear();
      }

      setTimeout(() => {
        this.items.push(item);
      }, 0);

      return item;
    },

    hide(payload: { id: string }) {
      const { id } = payload;

      this.items = this.items.filter((item) => item.id !== id);
    },

    hideAll() {
      this.items = [];
    },

    setMargin(payload: ToastMargin) {
      this.margin = payload;
    },
  },
});
