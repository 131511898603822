<template>
  <!-- /launch/launching -->
  <launch-loading v-if="launchStatus === 'UNSET' || launchStatus === 'LAUNCHING'" />

  <!-- /launch/visit_first -->
  <launch-visit-first v-if="launchStatus === 'VISIT_FIRST'" />

  <!-- /launch/need_to_update -->
  <launch-need-to-update v-if="launchStatus === 'NEED_APP_UPDATE'" />

  <!-- /launch/error -->
  <launch-error :error="state.error" v-if="launchStatus === 'ERROR'" />

  <!-- /launch/banned -->
  <launch-banned v-if="launchStatus === 'BANNED'" />
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import LaunchBanned from '@/components/resources/launch/LaunchBanned.vue';
import LaunchError from '@/components/resources/launch/LaunchError.vue';
import LaunchLoading from '@/components/resources/launch/LaunchLoading.vue';
import LaunchNeedToUpdate from '@/components/resources/launch/LaunchNeedToUpdate.vue';
import LaunchVisitFirst from '@/components/resources/launch/LaunchVisitFirst.vue';
import { APP_STATUS, useAppStore } from '@/stores/modules/app';
import { useSessionStore } from '@/stores/modules/session';
import logger from '@/utils/logger';

const debug = logger('app:pages:launch');

const route = useRoute();
const app = useAppStore();
const session = useSessionStore();

const emit = defineEmits(['initialize', 'error']);

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
});

const state = reactive({
  is_ready: false,
  error: null,
});
const launchStatus = computed(() => {
  const value = props.status || route.params.status.toString().toUpperCase() || 'UNSET';
  return value;
});

onMounted(() => {
  onReady();
});

async function onReady() {
  if (state.is_ready) {
    return;
  }
  try {
    state.is_ready = true;

    await initializeApp();
    await initializeSessions();

    if (!app.isFirstOpened && (route.path.includes('biz') || route.path.includes('policy'))) {
      app.setStatus(APP_STATUS.EXTERNAL);
    } else {
      await app.start();
    }
  } catch (e) {
    const isNetworkError = e.message === 'Network Error';

    state.error = e;
    app.status = APP_STATUS.ERROR;
  } finally {
    emit('initialize', session);
  }
}

async function initializeApp() {
  debug('initializeApp');

  try {
    if (!app.initialized) {
      await app.loadDeviceInfo({});
      await app.pushRegister({});
      await app.initialize({});
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  // @TODO: 버전 체크, zkp 적용
}

async function initializeSessions() {
  debug('initializeSessions');
  try {
    await setupSession();
    await setupSDKSession();
  } catch (e) {
    console.error(e);
    await session.unregister();
    await session.unregisterSDK();
    // @TODO toast error 노출 필요\
  }
}

async function setupSession() {
  debug('setupSession');
  try {
    // if (wni.isNative) {
    if (session.accessToken) {
      await session.verify({});
    } else {
      await session.anonymous({});
    }
    // } else {
    //   if (session.accessToken) {
    //     await session.verify({});
    //   } else {
    //     throw new Error('토큰이 없어요');
    //   }
    // }
  } catch (e) {
    throw e;
  }
}

async function setupSDKSession() {
  debug('setupSDKSession');
  try {
    const skdToken = session.sdkToken;

    if (skdToken) {
      try {
        await session.verifySDK();
      } catch (e) {
        await session.authorizeSDK();
      }
    } else {
      await session.authorizeSDK();
    }
  } catch (e) {
    throw e;
  }
}
</script>

<style lang="scss" scoped></style>
