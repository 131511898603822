/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AppUpdateDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AppApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerInitialize
   * @summary 앱 초기화
   * @request GET:/v1/apps/initialize
   * @secure
   */
  appControllerInitialize = (
    query: { app_id: string; app_version: string; app_os: string; device_id?: string; push_token?: string },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/v1/apps/initialize`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerGetAppScopes
   * @summary 앱 정책 조회
   * @request GET:/v1/apps/scopes
   */
  appControllerGetAppScopes = (query: { app_key: string }, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/apps/scopes`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerGetAppConfig
   * @summary 앱 Config 조회
   * @request GET:/v1/apps/config
   * @secure
   */
  appControllerGetAppConfig = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/apps/config`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerAppList
   * @summary 앱 리스트 조회
   * @request GET:/v1/apps
   * @secure
   */
  appControllerAppList = (query?: { page?: number; limit?: number }, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/apps`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerAppDetail
   * @summary 앱 정보 상세 조회
   * @request GET:/v1/apps/{id}
   * @secure
   */
  appControllerAppDetail = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/apps/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerAppUpdate
   * @summary 앱 정보 업데이트
   * @request PUT:/v1/apps/{id}
   * @secure
   */
  appControllerAppUpdate = (id: number, data: AppUpdateDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/apps/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags app-api
   * @name AppControllerAppRemove
   * @summary 앱 정보 삭제
   * @request DELETE:/v1/apps/{id}
   * @secure
   */
  appControllerAppRemove = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/apps/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
