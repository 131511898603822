<template>
  <teleport to="#teleport-area">
    <div class="toast-container">
      <transition-group tag="ul" name="toast">
        <toast-item v-for="item in items" :key="item.id" :data="item" />
      </transition-group>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useToastStore } from '@/stores/modules/toast';
import ToastItem from './ToastItem.vue';

const toastModule = useToastStore();

const items = computed(() => toastModule.items);
</script>

<style lang="scss" scoped>
.toast-container {
  display: block;

  .toast-move,
  .toast-enter-active,
  .toast-leave-active {
    transition: all 0.5s ease;
  }

  .toast-enter-from,
  .toast-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>
