import * as amplitude from '@amplitude/analytics-browser';

import { WNInterface, useWNInterface } from '@/plugins/vue-wni';

import { AnalyticsModule } from '..';

export default class Amplitude implements AnalyticsModule {
  private _apiKey: string;
  private _mappingEvents: any;
  private _wni: WNInterface;
  private _identifyEvent: any;

  constructor({ apiKey }) {
    this._apiKey = apiKey;
    this._mappingEvents = {};
    this._wni = useWNInterface();
  }

  init() {
    if (this._apiKey) {
      amplitude.init(this._apiKey, null, {
        defaultTracking: false,
        minIdLength: 1,
      });
    } else {
      console.error('undefined apiKey');
    }
  }

  setUserId(id: string) {
    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAmplitudeSetUserId', { id });
    } else {
      amplitude.setUserId(id);
    }
  }

  setUserProperty(key: string, value: any) {
    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAmplitudeSetUserProperty', { key, value });
    } else {
      const identify = new amplitude.Identify().set(key, value);
      amplitude.identify(identify);
    }
  }

  addUserProperty(key: string, value: number) {
    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAmplitudeAddUserProperty', { key, value });
    } else {
      const identify = new amplitude.Identify().add(key, value);
      amplitude.identify(identify);
    }
  }

  clearUser() {
    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAmplitudeResetUser', {});
    } else {
      amplitude.reset();
    }
  }

  event(name: any, options?: { common: any; amplitude: any; [key: string]: any }) {
    const { common = {}, amplitude: amplitudeProperties = {} } = options ?? {};
    const eventName = this._mappingEvents[name] ?? name;

    if (!eventName) {
      return;
    }

    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAmplitudeSendEvent', {
        eventName,
        eventProperties: {
          ...common,
          ...amplitudeProperties,
        },
      });
    } else {
      amplitude.track(eventName, {
        ...common,
        ...amplitudeProperties,
      });
    }
  }
}
