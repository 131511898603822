import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/pages/common/layouts/Layout.vue';
import common from '@/router/pages/common';
import explore from '@/router/pages/explore';
import home from '@/router/pages/home';
import message from '@/router/pages/message';
import mypage from '@/router/pages/mypage';
import permissionGuard from './guarde/permission.guard';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition): any {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: { name: 'Home' },
      children: [...home, ...explore, ...message, ...mypage, ...common],
    },
    {
      path: '/biz',
      redirect: { name: '404' },
      component: Layout,
      children: [
        {
          path: ':provider_type',
          component: () => import('@/pages/biz/PageAcuisitionRegisterSpace.vue'),

          name: 'BizRegisterProvider',
          meta: { title: '공간 등록 랜딩' },
        },
        {
          path: 'register-completion',
          component: () => import('@/pages/biz/PageAcuisitionRegisterSpaceCompletion.vue'),
          name: 'BizRegisterCompletion',
          meta: { title: 'biz 공간 등록 완료' },
        },
      ],
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('@/pages/common/error/404.vue'),
      name: '404',
      meta: { title: 'Error 404' },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.hash) {
    if (from && from.name === to.name && from.path === to.path) {
      next(false);
      return;
    }
  }

  if (from.name === 'SpaceRegisterCompletion' || from.name === 'Home') {
    if (to.path.includes('register/size') || to.path.includes('register/address')) {
      return next(false);
    }
  }

  if (from.name === 'Home' || from.name === 'SpaceRegisterAdditionalCompletion' || from.name === 'SpaceDetail') {
    if (to.path.includes('register') && to?.params?.id) {
      return next(false);
    }
  }

  if (to.name === 'Onboarding') {
    if (from.redirectedFrom) {
      to.redirectedFrom = from.redirectedFrom;
    } else {
      to.redirectedFrom = from;
    }
  }

  await permissionGuard.checkPermission(to, from);
  return permissionGuard.permissionHandler(next);
});

router.afterEach(async (to) => {});

export default router;
