export default [
  {
    path: '/common/launch/:status',
    component: () => import('@/pages/common/launch/PageLaunch.vue'),
    name: 'Launch',
    meta: { title: '' },
  },

  // host
  {
    path: '/common/host/:id/space',
    component: () => import('@/pages/common/host/PageHostSpaceList.vue'),
    name: 'HostSpaceList',
    meta: { title: '호스트의 등록된 공간' },
  },
  // space
  {
    path: '/common/space/list',
    component: () => import('@/pages/common/space/PageSpaceList.vue'),
    name: 'SpaceList',
    meta: { title: '공간 목록' },
  },
  {
    path: '/common/space/:id',
    component: () => import('@/pages/common/space/PageSpaceDetail.vue'),
    name: 'SpaceDetail',
    meta: { title: '공간 정보' },
  },
  // 공간 등록
  {
    path: '/common/space/register/intro',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterIntro.vue'),
    name: 'IntroSpaceRegister',
    meta: { title: '공간 등록 소개', auth: true },
  },
  {
    path: '/common/space/register/address',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterAddress.vue'),
    name: 'SpaceRegisterAddress',
    meta: { title: '공간 주소 등록', auth: true },
  },

  {
    path: '/common/space/register/size',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterSize.vue'),
    name: 'SpaceRegisterSize',
    meta: { title: '공간 크기 등록', auth: true },
  },
  {
    path: '/common/space/register/media',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterMedia.vue'),
    name: 'SpaceRegisterMedia',
    meta: { title: '공간 사진 등록', auth: true },
  },
  {
    path: '/common/space/:id/register/completion',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterCompletion.vue'),
    name: 'SpaceRegisterCompletion',
    meta: { title: '공간 기본 정보 등록 완료', auth: true },
  },
  {
    path: '/common/space/:id/register/additional-completion',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterAdditionalCompletion.vue'),
    name: 'SpaceRegisterAdditionalCompletion',
    meta: { title: '공간 추가 정보 등록 완료', auth: true },
  },
  {
    path: '/common/space/:id/register/media',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterMedia.vue'),
    name: 'SpaceRegisterMedia',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/common/space/:id/register/facility',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterFacility.vue'),
    name: 'SpaceRegisterFacility',
    meta: { title: '공간 시설 등록', auth: true },
  },
  {
    path: '/common/space/:id/register/tiem-table',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterTimetable.vue'),
    name: 'SpaceRegisterTimetable',
    meta: { title: '공간 사용 시간 등록', auth: true },
  },
  {
    path: '/common/space/:id/register/description',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterDescription.vue'),
    name: 'SpaceRegisterDescription',
    meta: { title: '공간 이용 공지 등록', auth: true },
  },
  {
    path: '/common/space/:id/edit',
    component: () => import('@/pages/common/space/PageSpaceEdit.vue'),
    name: 'SpaceEdit',
    meta: { title: '공간 정보 수정', auth: true },
  },
  {
    path: '/common/space/:id/edit/address',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterAddress.vue'),
    name: 'SpaceEditAddress',
    meta: { title: '공간 주소 수정', auth: true },
  },
  {
    path: '/common/space/:id/edit/size',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterSize.vue'),
    name: 'SpaceEditSize',
    meta: { title: '공간 크기 수정', auth: true },
  },
  {
    path: '/common/space/:id/edit/description',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterDescription.vue'),
    name: 'SpaceEditDescription',
    meta: { title: '공간 이용 공지 수정', auth: true },
  },
  {
    path: '/common/space/:id/edit/facility',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterFacility.vue'),
    name: 'SpaceEditFacility',
    meta: { title: '공간 시설 수정', auth: true },
  },
  {
    path: '/common/space/:id/edit/media',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterMedia.vue'),
    name: 'SpaceEditMedia',
    meta: { title: '공간 사진 수정', auth: true },
  },
  {
    path: '/common/space/:id/edit/tiem-table',
    component: () => import('@/pages/common/space/register/PageSpaceRegisterTimetable.vue'),
    name: 'SpaceEditTimetable',
    meta: { title: '공간 이용시간 수정', auth: true },
  },
  {
    path: '/common/space/:id/book',
    component: () => import('@/pages/common/space/book/PageSpaceBook.vue'),
    name: 'SpaceBook',
    meta: { title: '공간 사용 신청', auth: true },
  },
  {
    path: '/common/space/:id/book/completion',
    component: () => import('@/pages/common/space/book/PageSpaceBookCompletion.vue'),
    name: 'SpaceReservationCompletion',
    meta: { title: '공간 사용 신청 완료', auth: true },
  },

  // space-request
  {
    path: '/common/space-request/create',
    component: () => import('@/pages/common/space-request/create/PageSpaceRequestCreate.vue'),
    name: 'SpaceRequestCreate',
    meta: { title: '공간 구하기 작성', auth: true },
  },
  {
    path: '/common/space-request/create/completion/:id',
    component: () => import('@/pages/common/space-request/create/PageSpaceRequestCreateCompletion.vue'),
    name: 'SpaceRequestCompletion',
    meta: { title: '공간 구하기 작성 완료', auth: true },
  },
  {
    path: '/common/space-request/list',
    component: () => import('@/pages/common/space-request/PageSpaceRequestList.vue'),
    name: 'SpaceRequestList',
    meta: { title: '공간 구하기 목록' },
  },
  {
    path: '/common/space-request/:id',
    component: () => import('@/pages/common/space-request/PageSpaceRequestDetail.vue'),
    name: 'SpaceRequestDetail',
    meta: { title: '공간 구하기 상세' },
  },
  {
    path: '/common/space-request/:id/edit',
    component: () => import('@/pages/common/space-request/PageSpaceRequestEdit.vue'),
    name: 'SpaceRequestEdit',
    meta: { title: '공간 구하기 수정', auth: true },
  },
  {
    path: '/common/space-request/:id/proposal',
    component: () => import('@/pages/common/space-request/proposal/PageSpaceProposal.vue'),
    name: 'SpaceProposal',
    meta: { title: '공간 제안 하기', auth: true },
  },
  {
    path: '/common/space-request/:id/proposal/completion',
    component: () => import('@/pages/common/space-request/proposal/PageSpaceProposalCompletion.vue'),
    name: 'SpaceProposalCompletion',
    meta: { title: '공간 제안 완료', auth: true },
  },
];
