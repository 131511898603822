import { useSessionStore } from '@/stores/modules/session';

class PermissionGuard {
  _permissionHandler: Function = () => {};

  setPermissionHandler(handler: Function) {
    this._permissionHandler = handler;
  }

  clearPermissionHandler() {
    this._permissionHandler = () => {};
  }

  get permissionHandler() {
    return this._permissionHandler;
  }

  async checkPermission(to: any, from: any) {
    this.clearPermissionHandler();
    const { auth } = to.meta ?? {};

    const session = useSessionStore();
    await session.init();

    const isLoggedIn = session.isLogged;

    if (auth) {
      if (!isLoggedIn) {
        return this.setPermissionHandler((next: Function) => {
          const queryString = parseQuery(to.query);
          next({
            name: 'PassportByPhone',
            query: { redirect: encodeURIComponent(to.path + '?' + queryString) },
          });
        });
      }
    }

    return this.setPermissionHandler((next: Function) => next(true));
  }
}

function parseQuery(query) {
  return '';
}

const guard = new PermissionGuard();
export default guard;
