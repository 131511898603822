<template>
  <template v-if="state.inviteCode">
    <div class="page-content">
      <div class="logo">
        <img src="/imgs/common/logo.png" alt="UHOO" />
      </div>
      <h1 class="title">
        {{ $t('환영합니다') }}
      </h1>
      <p
        class="message"
        v-html="
          $t('<em>{inviteUserName}</em>님을 통해<br /> 초대를 받으셨군요?', {
            inviteUserName: state.inviteUserName,
          })
        "
      ></p>
    </div>
    <page-actions>
      <button type="button" class="btn-large btn-filled" @click="onClickInviteConfirm">
        <span>{{ $t('예, 유후와 함께 할게요') }}</span>
      </button>

      <button type="button" class="btn-text" @click="onClickInviteSkip">
        <span>{{ $t('아니오, 괜찮아요') }}</span>
      </button>
    </page-actions>
  </template>
  <template v-else>
    <div class="page-content">
      <div class="logo">
        <img src="/imgs/common/logo.png" alt="UHOO" />
      </div>
      <div class="slogan">
        <p>{{ $t('유휴 공간의 변신') }}</p>
      </div>
    </div>
    <page-actions>
      <button type="button" class="btn-large btn-filled" @click="onClickStart">
        <span>{{ $t('지금부터 유후~ 할까요?') }}</span>
      </button>
    </page-actions>
  </template>
</template>

<script lang="ts" setup>
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import PageActions from '@/components/commons/layout/PageActions.vue';
import { $t } from '@/plugins/locale';
import { useAppStore } from '@/stores/modules/app';
import logger from '@/utils/logger';

const router = useRouter();
const app = useAppStore();
const debug = logger('app:components:resources:landing:LaunchVisitFirst');

const state = reactive({
  loading: false,
  inviteCode: '',
  inviteUserName: '',
  // inviteCode: '1234', inviteUserName: '아무개아무개아무개아',
});

onMounted(() => {});

function onClickInviteConfirm() {
  debug('onClickInviteConfirm');

  onInviteConfirm();
}

function onClickInviteSkip() {
  debug('onClickInviteSkip');

  // onClickNext();
}

async function onClickStart() {
  debug('onClickStart');

  // useAnalytics().event('click_first_start');
  await onAppStart();
}

async function onInviteConfirm() {
  debug('onInviteConfirm');
  try {
    const options = { code: state.inviteCode };
    // await $store.dispatch("campaign/onBoardingInvite", options)

    await onAppStart();
  } catch (e) {
    console.error(e);
  }
}

async function getUserCode() {
  debug('getUserCode');

  try {
    // const options = {
    //   code: this.$data.inviteCode,
    // };
    // const data = await this.$store.dispatch('campaign/inviteCodeCheck', options);
    // const { user } = data;
    // this.$data.inviteUserName = user.display_name;
  } catch (e) {
    console.error(e);
  }
}

async function onInit() {
  debug('onInit');

  const pathname = location.pathname;

  if (pathname.indexOf('/share/invite/') > -1) {
    state.inviteCode = pathname.split('/share/invite/')[1];

    await getUserCode();
  }
}

async function onAppStart() {
  debug('onAppStart');

  try {
    state.loading = true;

    await router.push({ name: 'Onboarding' });

    await app.firstOpen();
    await app.start({});
  } catch (e) {
    console.error(e);
  } finally {
    state.loading = false;
  }
}

onInit();
</script>

<style lang="scss" scoped>
.page-content {
  @include column-flexbox(start);
  padding-top: 55%;

  .logo {
    position: relative;
    width: 70%;
    padding-top: 25%;

    img {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .title {
    @include text-variant(title);
    margin-top: unit(32);
  }

  .slogan {
    margin-top: unit(16);
    width: 100%;
    height: unit(26);

    p {
      text-align: center;
      font-size: unit(18);
      font-weight: 600;
    }
  }

  .message {
    @include text-variant(subhead1);
    margin-top: unit(16);
    text-align: center;
    word-wrap: break-word;
    word-break: keep-all;

    &:deep() {
      em {
        font-weight: 700;
      }
    }
  }
}
.btn-text {
  @include text-variant(button2, $color-gray-120);
  display: block;
  margin: 0 auto;
  background-color: transparent;
  text-decoration: underline;
}
</style>
