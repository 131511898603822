export default [
  {
    path: '/my',
    component: () => import('@/pages/mypage/PageMypage.vue'),
    name: 'Mypage',
    meta: { title: '마이페이지' },
  },
  {
    path: '/my/onboarding',
    component: () => import('@/pages/mypage/PageOnboarding.vue'),
    name: 'Onboarding',
    meta: { title: '유후 시작 가이드' },
  },
  {
    path: '/my/passport/phone',
    component: () => import('@/pages/mypage/passport/PagePassportByPhone.vue'),
    name: 'PassportByPhone',
    meta: { title: '로그인/회원가입' },
  },
  {
    path: '/my/profile-update',
    component: () => import('@/pages/mypage/PageProfileUpdate.vue'),
    name: 'ProfileUpdate',
    meta: { title: '프로필 설정', auth: true },
  },
  {
    path: '/my/spaces',
    component: () => import('@/pages/mypage/PageMySpaces.vue'),
    name: 'MySpaces',
    meta: { title: '등록한 공간', auth: true },
  },
  {
    path: '/my/books/to',
    component: () => import('@/pages/mypage/PageMyBooksTo.vue'),
    name: 'MyBooksTo',
    meta: { title: '들어온 예약', auth: true },
  },
  {
    path: '/my/books/from',
    component: () => import('@/pages/mypage/PageMyBooksFrom.vue'),
    name: 'MyBooksFrom',
    meta: { title: '문의한 공간', auth: true },
  },
  {
    path: '/my/books/:id',
    component: () => import('@/pages/mypage/PageBooksDetail.vue'),
    name: 'MyBooksDetail',
    meta: { title: '문의 내용', auth: true },
  },
  {
    path: '/my/proposal',
    component: () => import('@/pages/mypage/PageProposal.vue'),
    name: 'MyProposal',
    meta: { title: '제안 공간', auth: true },
  },
  {
    path: '/my/space-request',
    component: () => import('@/pages/mypage/PageMySpaceRequest.vue'),
    name: 'MySpaceRequest',
    meta: { title: '마이 공간 구하기', auth: true },
  },
  {
    path: '/my/app-setting',
    component: () => import('@/pages/mypage/PageAppSetting.vue'),
    name: 'AppSetting',
    meta: { title: '앱 설정' },
  },
  {
    path: '/my/favorite',
    component: () => import('@/pages/mypage/PageFavorite.vue'),
    name: 'Favorite',
    meta: { title: '관심', auth: true },
  },
  {
    path: '/my/policy/agrees',
    component: () => import('@/pages/mypage/policy/PageAgreeToPolicy.vue'),
    name: 'AgreeToPolicy',
  },
  {
    path: '/my/policy/:path_key',
    component: () => import('@/pages/mypage/policy/PagePolicy.vue'),
    name: 'Policy',
  },
  {
    path: '/my/support/faq',
    component: () => import('@/pages/mypage/support/PageFaq.vue'),
    name: 'FAQ',
  },
  {
    path: '/my/support/:id',
    component: () => import('@/pages/mypage/support/PageGuide.vue'),
    name: 'Guide',
  },
  {
    path: '/my/payment',
    component: () => import('@/pages/mypage/payment/PagePaymentList.vue'),
    name: 'PagePaymentList',
    meta: { title: '결제수단', auth: true },
  },
  {
    path: '/my/payment/add',
    component: () => import('@/pages/mypage/payment/PageAddPayment.vue'),
    name: 'AddPayment',
    meta: { title: '결제수단 추가', auth: true },
  },
  {
    path: '/my/payment/add/eximbay',
    component: () => import('@/pages/mypage/payment/PageAddPaymentEximbay.vue'),
    name: 'AddPaymentEximbay',
    meta: { title: 'AddPaymentEximbay' },
    props: true,
  },
  {
    path: '/my/payment/add/nicepay',
    component: () => import('@/pages/mypage/payment/PageAddPaymentNicepay.vue'),
    name: 'AddPaymentNicepay',
    meta: { title: 'AddPaymentNicepay' },
    props: true,
  },

  // for KakaoPay
  {
    path: '/my/payment/add/kakaopay',
    component: () => import('@/pages/mypage/payment/PageAddPaymentKakaopay.vue'),
    name: 'AddPaymentKakaopay',
    meta: { title: 'AddPaymentKakaopay' },
    props: true,
  },
  {
    path: '/my/payment/add/kakaopay/popup/:token/:state',
    component: () => import('@/pages/mypage/payment/PageAddPaymentKakaopayPopup.vue'),
    name: 'AddPaymentKakaopayPopup',
    meta: { title: 'AddPaymentKakaopayPopup' },
    props: true,
  },
  {
    path: '/my/payment/add/easy-payment-regist',
    component: () => import('@/pages/mypage/payment/PageAddPaymentEasypayment.vue'),
    name: 'AddPaymentEasypayment',
    meta: { title: 'AddPaymentEasypayment' },
    props: true,
  },
];
