<template>
  <button type="button" @click="onClick">
    <slot></slot>
  </button>
</template>

<script lang="ts">
export default {
  name: 'NotionLink',
  props: {
    action: Function,
    target: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log(this.$props);
  },
  methods: {
    onClick() {
      if (typeof this.$props.action === 'function') {
        this.$props.action({
          action: 'link',
          data: {
            link: this.$props.href,
            target: this.$props.target,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
