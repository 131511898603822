import axios from 'axios';
import config from '@/config';

axios.defaults.baseURL = config.apiServer;
axios.interceptors.request.use(
  function (config) {
    const { pathname = '', search = '' } = window.location;
    config.headers['page-location'] = pathname + search;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export function setAccessToken(accessToken: string | null) {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

export function setDeviceInfo(deviceInfo: any) {
  if (deviceInfo) {
    axios.defaults.headers.common['x-device-info'] = JSON.stringify(deviceInfo);
  } else {
    delete axios.defaults.headers.common['x-device-info'];
  }
}

export const sdkAxios = axios.create({
  baseURL: config.apiServer,
});

sdkAxios.interceptors.request.use((config) => {
  config.baseURL;
  return config;
});

export function setSDKAccessToken(accessToken: string | null) {
  if (accessToken) {
    sdkAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete sdkAxios.defaults.headers.common.Authorization;
  }
}

export default axios;
