import SwiperCore, { Autoplay, Controller, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Controller, Pagination, Autoplay]);

const plugin = {
  install: (app: any) => {
    app.component('Swiper', Swiper);
    app.component('SwiperSlide', SwiperSlide);
  },
};

export default plugin;
